import {Fragment, useState} from "react";
import {useApi, useToken} from "components/token";
import {UsersIcon} from "@heroicons/react/24/solid";
import {Button, If, Loading, byKeywords, SearchBox} from "components/ui";

export function UndecidedNotification() {
	return (
		<div className="inline-flex justify-center items-center opacity-50 group-hover/navli:opacity-100">
			<span className="animate-ping absolute inline-flex size-5 rounded-full bg-red-400 opacity-75"></span>
			<span className="relative inline-flex rounded-full size-3 bg-red-500"></span>
		</div>
	);
}

export function AwardRequestLog({awardRequest, editable}) {
	let [prevLog, saveLog] = useState();
	let [log, fetchLog] = useApi("GET", "/awards/request/" + awardRequest.requestId + "/log");

	let [submit, setSubmit] = useState(false);
	let [, submitForm] = useApi();

	if (log && log.length === 0) {
		return <></>;
	}
	let entries = log ?? prevLog ?? [];

	function handleSubmit(formData) {
		setSubmit(true);
		saveLog(entries);
		submitForm("POST", `/awards/request/${awardRequest.requestId}/log`, {body: formData})
			.then(() => fetchLog())
			.then(() => setSubmit(false));
	}

	return (
		<div className="p-3 bg-slate-50 shadow-inner rounded text-white grid grid-flow-row gap-1">
			<Loading condition={log || prevLog} className="w-1/5 max-w-16 mx-auto">
				<div className="grid grid-flow-row gap-1">
					{entries.map((r, i) => <AwardRequestLogEntry key={i} entry={r}/>)}
				</div>
				<If condition={editable && awardRequest.state === "UNDECIDED"}>
					<Loading condition={!submit} className="w-1/5 max-w-16 mx-auto">
						<AwardRequestLogAppendForm awardRequest={awardRequest}
												   disabled={!log} onSubmit={handleSubmit}/>
					</Loading>
				</If>
			</Loading>
		</div>
	);
}

function AwardRequestLogEntry({entry}) {
	let {claims} = useToken();
	return (
		<div className={`p-3 ${entry.emailAddress === claims.emailAddress ? "ml-5 bg-emerald-600" : "mr-5 bg-slate-600"} rounded shadow grid grid-flow-row-dense gap-3`}>
			<div className="w-full grid grid-cols-2">
				<abbr title={entry.emailAddress}
					  className="no-underline text-xs font-bold truncate">{entry.emailAddress}</abbr>
				<span className="text-xs text-right">{entry.createdAt}</span>
			</div>
			<span className="min-w-0 text-xs break-words">{entry.text}</span>
		</div>
	);
}

function AwardRequestLogAppendForm({disabled, onSubmit}) {
	let [text, setText] = useState("");

	function handleSubmit(e) {
		e.preventDefault();
		onSubmit({"text": text});
	}

	return (
		<form onSubmit={handleSubmit} className="bg-emerald-600 rounded shadow flex justify-center gap-1">
			<input required value={text} maxLength="256" onChange={(e) => setText(e.target.value)}
				   placeholder="Type here to enter message" disabled={disabled}
				   className="p-2 focus:outline-none w-full placeholder:text-white text-sm bg-transparent"/>
			<Button type="submit" disabled={disabled}
					className="bg-emerald-600 hover:bg-emerald-700 rounded-none rounded-r border-l shadow-none">SEND</Button>
		</form>
	);
}

function compareTemplateDisplayName(t1, t2) {
	return t1.displayName.localeCompare(t2.displayName);
}

export function TemplateSearch({templates, placeholder, limit, children, ...props}) {
	let [value, setValue] = useState("");

	templates.sort(compareTemplateDisplayName);
	let results = templates.filter(byKeywords(value, t => [t.displayName, t.description])).slice(0, limit);

	return (
		<div className="flex flex-col gap-1 lg:gap-5">
			<SearchBox placeholder={placeholder} value={value} onChange={(e) => setValue(e.target.value)}
					   className="p-3 bg-white rounded shadow">
				<hr/>
				<span className="text-xs text-slate-500">You are seeing {results.length} of total {templates.length} results</span>
			</SearchBox>
			<div {...props}>
				{results.map((t, i) => <Fragment key={i}>{children(t)}</Fragment>)}
			</div>
		</div>
	);
}

function compareAwardRequestState(r1, r2) {
	return (r2.state === "UNDECIDED") - (r1.state === "UNDECIDED");
}

function compareAwardRequestCreatedAt(r1, r2) {
	return r2.createdAt.localeCompare(r1.createdAt)
}

export function AwardRequestSearch({awardRequests, placeholder, limit, children, ...props}) {
	let [value, setValue] = useState("");
	awardRequests.sort((r1, r2) => compareAwardRequestState(r1, r2) || compareAwardRequestCreatedAt(r1, r2));
	let results = awardRequests.filter(byKeywords(value,
			r => [r.template.displayName, r.template.description])).slice(0, limit);
	return (
		<div className="flex flex-col gap-1 lg:gap-5">
			<SearchBox placeholder={placeholder} value={value} onChange={(e) => setValue(e.target.value)}
					   className="p-3 bg-white rounded shadow">
				<hr/>
				<span className="text-xs text-slate-500">You are seeing {results.length} of total {awardRequests.length} results</span>
			</SearchBox>
			<div {...props}>
				{results.map((r, i) => <Fragment key={i}>{children(r)}</Fragment>)}
			</div>
		</div>
	);
}

export function AwardRequestSearchResult({awardRequest, ...props}) {
	let template = awardRequest.template;
	let recipients = awardRequest.recipients;
	return (
		<div {...props} className="group bg-white p-3 rounded shadow hover:shadow-lg z-0 hover:z-10 grid grid-flow-col-dense grid-cols-5 gap-1 items-center cursor-pointer">
			<abbr title={template.displayName}
				  className="no-underline col-span-2 truncate flex flex-row gap-1 items-center">
				<If condition={awardRequest.state === "UNDECIDED"}>
					<UndecidedNotification/>
				</If>
				<span className="text-xs group-hover:font-bold text-slate-600">{template.displayName}</span>
			</abbr>
			<span className="space-x-1">
                <UsersIcon className="inline size-4 text-slate-500"/>
                <span className="text-xs text-slate-500 truncate">{recipients.length}</span>
            </span>
			<span className="capitalize text-xs text-slate-600 font-bold truncate">{awardRequest.state}</span>
			<span className="text-right text-xs text-slate-500 truncate">{awardRequest.createdAt}</span>
		</div>
	);
}

export function AwardSearch({awards, placeholder, limit, children, ...props}) {
	let [value, setValue] = useState("");

	let results = awards.filter(byKeywords(value,
		a => [a.template.displayName, a.template.description])).slice(0, limit);

	return (
		<div className="flex flex-col gap-1 lg:gap-5">
			<SearchBox placeholder={placeholder} value={value} onChange={(e) => setValue(e.target.value)}
					   className="p-3 bg-white rounded shadow">
				<hr/>
				<span className="text-xs text-slate-500">You are seeing {results.length} of total {awards.length} results</span>
			</SearchBox>
			<div {...props}>
				{results.map((a, i) => <Fragment key={i}>{children(a)}</Fragment>)}
			</div>
		</div>
	);
}