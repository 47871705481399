import {useState} from "react";
import {Switch} from "@headlessui/react";
import {useApi} from "components/token";
import {SignInSessionSummary} from "components/sign-in";
import {
    Button,
    If,
    Loading,
    Option, byKeywords,
    SearchBox,
    Select
} from "components/ui";
import {
	Page, PageModal,
	PageNav, PageNavBreadcrumbs, PageNavCrumb, PageNavCrumbLink
} from "components/layout";

export default function SignInAdmin() {
    let [open, setOpen] = useState(false);
    return (
        <Page title="Sign-in">
			<PageNav>
				<PageNavBreadcrumbs>
					<PageNavCrumb title="Sign-in"/>
					<PageNavCrumbLink title="Admin" href="/sign-in/admin"/>
				</PageNavBreadcrumbs>
				<div className="flex flex-row items-center gap-1 flex-wrap">
					<Button type="button" onClick={(e) => setOpen(true)}>SEND NOTIFICATION</Button>
				</div>
			</PageNav>
            <PageModal open={open} setOpen={setOpen}>
                <SignInAdminNotificationForm/>
            </PageModal>
            <SignInSessionSummary/>
        </Page>
    );
}

function SignInAdminNotificationForm() {
    let [tags] = useApi("GET", "/sign-in/tags");
    let [tag, setTag] = useState(null);
    let users = (tags ?? {})[tag] ?? [];

    let [sessions] = useApi("GET", "/sign-in/sessions/active");

    let [, sendNotification] = useApi();
    let [recipients, setRecipients] = useState([]);
    let [sent, setSent] = useState(true);

    function handleSubmit(formData) {
        setSent(false);
        sendNotification("POST", "/sign-in/reminder", {body: formData})
            .then(() => {
                let recipients = formData["recipients"];
                setRecipients(recipients);
                setSent(true);
            });
    }

    return (
        <Loading condition={sent && tags && sessions} className="w-1/5 max-w-16 mx-auto">
            <div className="space-y-3">
                <If condition={recipients.length === 0}>
                    <Loading condition={tags} className="w-1/5 max-w-16 mx-auto">
                        <SignInNotificationTagForm tag={tag} setTag={setTag} tags={Object.keys(tags ?? {})}/>
                    </Loading>
                    <If condition={tag}>
                        <hr/>
                        <Loading condition={sessions} className="w-1/5 max-w-16 mx-auto">
                            <SignInAdminNotificationEmailForm onSubmit={handleSubmit} tag={tag} users={users} sessions={sessions ?? []}/>
                        </Loading>
                    </If>
                </If>
                <If condition={recipients.length > 0}>
                    <p className="text-xs text-slate-500">Sent notification to <span className="font-bold">{recipients.length}</span> of total {users.length} recipients</p>
                    <div className="grid grid-cols-1 lg:grid-cols-2 max-h-72 overflow-y-auto">
                        {
                            recipients.map((emailAddress, i) =>
                                <span key={i} className="text-xs text-slate-600 font-medium truncate">{emailAddress}</span>)
                        }
                    </div>
                </If>
            </div>
        </Loading>
    );
}

function SignInNotificationTagForm({tag, setTag, tags}) {
    return (
        <>
            <p className="text-xs text-slate-500">Select a tag to notify users to sign-in for the day</p>
            <Select value={tag} onChange={setTag} placeholder="Click here to select an option">
                {tags.map((t, i) => <Option key={i} value={t}>{t}</Option>)}
            </Select>
            <p className="text-xs text-slate-500">To tag users, visit their sign-in history</p>
        </>
    );
}

function SignInAdminNotificationEmailForm({onSubmit, tag, users, sessions}) {
    let activeSessions = sessions.map(s => s.emailAddress);
    let selectedList = users.map(u => [u, useState(!activeSessions.includes(u.emailAddress))]);
    selectedList.sort(([, [s1]], [, [s2]]) => s2-s1);

    let recipients = selectedList.filter(([,[s]]) => s).map(([u]) => u.emailAddress);

    let [search, setSearch] = useState("");

    function handleSubmit(e) {
        e.preventDefault();
        if (recipients.length > 0) {
            onSubmit({"recipients": recipients});
        }
    }

    return (
        <>
            <p className="text-xs text-slate-500">You are seeing users tagged with <span className="font-bold">{tag}</span>, users with active sessions are already unchecked</p>
            <SearchBox placeholder="Type here to search" autoFocus className="p-1 text-xs border border-slate-300 rounded"
                       value={search} onChange={(e) => setSearch(e.target.value)}/>
            <div className="grid grid-cols-1 lg:grid-cols-2 max-h-72 overflow-y-auto">
                {
                    selectedList.filter(byKeywords(search, ([u]) => [u.emailAddress, u.firstname, u.lastname]))
                        .map(([{firstname, lastname}, [selected, setSelected]], i) => (
                            <Switch.Group key={i}>
                                <Switch.Label className="shrink-0 flex flex-row gap-3 items-center flex-nowrap p-3 hover:bg-slate-50 rounded cursor-pointer">
                                    <Switch checked={selected} onChange={setSelected} className={`${selected ? "bg-blue-600" : "bg-slate-200"} relative inline-flex shrink-0 h-6 w-12 rounded-full items-center`}>
                                        <span className={`${selected ? "translate-x-7" : "translate-x-1"} inline-block size-4 transform rounded-full bg-white transition`}/>
                                    </Switch>
                                    <span className="text-xs text-slate-600 font-medium select-none cursor-pointer truncate">{firstname} {lastname}</span>
                                </Switch.Label>
                            </Switch.Group>
                        ))
                }
            </div>
            <form className="space-y-3" onSubmit={handleSubmit}>
                <p className="text-xs text-slate-500">Send notification to <span className="font-bold">{recipients.length}</span> of total {users.length} recipients</p>
                <Button type="submit" disabled={recipients.length === 0} className="w-full">SEND NOTIFICATION</Button>
            </form>
        </>
    );
}
