import {Children, createContext, useContext, useState} from "react";
import {Link, Navigate, Outlet, useLocation} from "react-router-dom";
import {Dialog, Tab} from "@headlessui/react";
import {Bars3Icon, ChevronRightIcon, XMarkIcon} from "@heroicons/react/24/solid";
import {HomeIcon} from "@heroicons/react/24/outline";
import {useApi, useToken} from "components/token";
import {UndecidedNotification} from "components/awards";
import {Button, If} from "components/ui";
import {Helmet} from "react-helmet";

export function Layout() {
	return (
		<div className="min-w-screen min-h-screen bg-slate-50 flex flex-col">
			<Outlet/>
		</div>
	);
}

export function NavigationLayout() {
	return (
		<>
			<div className="lg:hidden">
				<MobileNavigation>
					<SidebarNavigation/>
				</MobileNavigation>
			</div>
			<div className="p-1 lg:p-5 lg:pt-24 mx-auto max-w-screen-3xl w-full flex flex-row gap-5">
				<div className="shrink-0 max-lg:hidden max-w-xs w-full">
					<DesktopNavigation>
						<SidebarNavigation/>
					</DesktopNavigation>
				</div>
				<div className="w-full">
					<Outlet/>
				</div>
			</div>
		</>
	);
}

let mobileContext = createContext([]);
function MobileNavigation({children}) {
	let [open, setOpen] = useState(false);
	return (
		<mobileContext.Provider value={[open, setOpen]}>
			<Dialog open={open} className="relative z-20" onClose={() => setOpen(false)}>
				<div className="fixed inset-0 bg-black/25 backdrop-blur-sm"/>
				<div className="fixed inset-0 overflow-y-auto overflow-x-hidden">
					<Dialog.Panel className="min-h-full max-w-xs w-full bg-white p-5 flex flex-col gap-5">
						<button type="button" className="relative" onClick={() => setOpen(false)}>
							<XMarkIcon className="size-8 text-slate-600"/>
						</button>
						<div className="px-5">{children}</div>
					</Dialog.Panel>
				</div>
			</Dialog>
			<div className="sticky top-0 bg-white shadow z-10 overflow-y-auto overflow-x-hidden">
				<div className="flex flex-row items-center justify-between py-3 px-5">
					<button type="button" onClick={() => setOpen(true)}>
						<Bars3Icon className="size-8 text-slate-600"/>
					</button>
					<div className="max-w-xs w-full flex flex-row items-center justify-end gap-1">
						<div className="flex flex-col text-right">
							<div className="text-md text-slate-600">Manly Campus</div>
							<div className="text-xs text-slate-400">Northern Beaches Secondary College</div>
						</div>
						<img className="w-1/5" src="/logo.png" alt="Manly Campus logo"/>
					</div>
				</div>
			</div>
		</mobileContext.Provider>
	);
}

function DesktopNavigation({children}) {
	return (
		<div className="sticky top-0 z-10 overflow-y-auto overflow-x-hidden flex flex-col gap-5">
				<div className="flex flex-row items-center gap-5">
					<img className="w-1/5" src="/logo.png" alt="Manly Campus logo"/>
					<div className="flex flex-col">
						<div className="text-xl text-slate-600">Manly Campus</div>
						<div className="text-sm text-slate-400">Northern Beaches Secondary College</div>
					</div>
				</div>
				<div className="px-5">{children}</div>
		</div>
	);
}

function SidebarNavigation() {
	let {isExpired, claims, logout} = useToken();
	let groups = claims.groups ?? [];

	let {emailAddress, firstname, lastname} = claims;
	let displayName = `${firstname} ${lastname}`;

	return (
		<ul className="text-sm lg:text-xs space-y-9">
			<If condition={!isExpired}>
				<SidebarUl title={displayName}>
					<SidebarLi title="My profile" href={`/directory/${emailAddress}`}/>
					<SidebarLi title="Logout" href="/logout"/>
				</SidebarUl>
			</If>
			<SidebarUl title="Homepage">
				<SidebarLi title="Find a link" href="/"/>
			</SidebarUl>
			<SidebarUl title="Directory">
				<SidebarLi title="Find a user" href="/directory"/>
			</SidebarUl>
			<SidebarUl title="Timetable">
				<SidebarLi title="Find a timetable" href="/timetable"/>
				<SidebarLi title="Bell times" href="/timetable/bell-times"/>
			</SidebarUl>
			<If condition={isExpired || groups.includes("staff")}>
				<SidebarUl title="Sign-in">
					<SidebarLi title="Have you signed-in today?" href="/sign-in"/>
					<If condition={groups.includes("office")}>
						<SidebarLi title="Day-to-day operations" href="/sign-in/admin"/>
					</If>
				</SidebarUl>
			</If>
			<SidebarUl title="Awards">
				<SidebarLi title="View your awards/requests" href="/awards">
					<AwardRequestsNotification/>
				</SidebarLi>
				<If condition={groups.includes("office")}>
					<SidebarLi title="Find awards/requests" href="/awards/admin"/>
					<SidebarLi title="Manage award templates" href="/awards/admin/templates"/>
				</If>
			</SidebarUl>
			<SidebarUl title="How-to">
				<SidebarLi title="Connect to Wi-Fi" newTab href="/how-to/connect-to-wi-fi.pdf"/>
			</SidebarUl>
			<SidebarUl title="Forms & Policies">
				<SidebarLi title="All-My-Own-Work (7-10)" newTab href="/forms&policies/all-my-own-work-7-10.pdf"/>
				<SidebarLi title="All-My-Own-Work (11-12)" newTab href="/forms&policies/all-my-own-work-11-12.pdf"/>
			</SidebarUl>
		</ul>
	);
}

function SidebarUl({title, children}) {
	return (
		<li className="group">
			<span className="font-bold text-slate-600">{title}</span>
			<ul className="mt-4 space-y-4 border-l-2 border-slate-400 group-hover:border-slate-600">{children}</ul>
		</li>
	);
}

function SidebarLi({title, href, newTab=false, children}) {
	let [open, setOpen] = useContext(mobileContext);
	return (
		<li className="relative group">
			<Link target={newTab ? "_blank" : "_self"} to={href} onClick={() => open && setOpen(false)}>
				<div className="pl-3.5 before:pointer-events-none before:absolute before:-left-1 before:top-1/2 before:h-1.5 before:w-1.5 before:-translate-y-1/2 before:rounded-full before:hidden before:bg-slate-600 hover:before:block">
					<div className="flex flex-row gap-1">
						<span className="text-slate-600 hover:font-bold">{title}</span>
						{children}
					</div>
				</div>
			</Link>
		</li>
	);
}

function AwardRequestsNotification() {
	let [requests] = useApi("GET", `/awards/requests/undecided`);
	return (
		<If condition={requests && requests.length > 0}>
			<UndecidedNotification/>
		</If>
	);
}

export function ProtectedLayout({group}) {
	let location = useLocation();
	let {isExpired, claims} = useToken();

	if (isExpired) {
		return <Navigate replace to={"/login?returnUrl=" + location.pathname}/>;
	}

	let claim = claims.groups ?? [];
	let groups = group ? group.split(" ") : [];
	return (
		<If condition={claim.length > 0}>
			{
				groups.length === 0 || groups.some(g => claim.includes(g)) ?
					<Outlet/> : <Navigate replace to="/"/>
			}
		</If>
	);
}

export function Page({title, children, ...props}) {
	const TITLE_SUFFIX = " - NBSC Manly Campus";
	return (
		<>
			<Helmet>
				<title>{title + TITLE_SUFFIX}</title>
			</Helmet>
			<div className="flex flex-col gap-1 lg:gap-5" {...props}>
				{children}
			</div>
		</>
	);
}

export function PageNav({children}) {
	return (
		<div className="p-3 w-full bg-white rounded shadow flex flex-col gap-3 flex-wrap">
			{children}
		</div>
	);
}

export function PageNavBreadcrumbs({children}) {
	let rest = Children.toArray(children);
	let last = rest.pop();

	let newChildren = [
		<span className="text-slate-400 hover:text-slate-600">
			<PageNavCrumbLink title={<HomeIcon className="w-5"/>} href="/"/>
		</span>
	];
	rest.forEach(i => {
		newChildren.push(<ChevronRightIcon className="w-4 text-slate-400"/>);
		newChildren.push(<span className="text-xs text-slate-400">{i}</span>);
	});
	newChildren.push(<ChevronRightIcon className="w-4 text-slate-400"/>);
	newChildren.push(<span className="text-sm text-slate-600 font-bold">{last}</span>);

	return (
		<div className="flex flex-row items-center gap-1 flex-wrap">
			{Children.toArray(newChildren)}
		</div>
	);
}

export function PageNavCrumb({title}) {
	return (
		<span className="truncate">{title}</span>
	);
}

export function PageNavCrumbLink({title, href, newTab=false}) {
	return (
		<Link target={newTab ? "_blank" : "_self"} to={href}>
			<span className="truncate hover:underline">{title}</span>
		</Link>
	);
}

export const modalContext = createContext();

export function PageModal({open, setOpen, children}) {
	return (
		<Dialog open={open} onClose={() => setOpen(false)} className="relative z-50">
			<div className="fixed inset-0 bg-black/30" aria-hidden="true"/>
			<div className="fixed inset-0 flex flex-col h-screen w-screen overflow-y-auto p-1">
				<Dialog.Panel className="m-auto max-w-lg w-full rounded bg-white p-5">
					<modalContext.Provider value={[open, setOpen]}>{children}</modalContext.Provider>
				</Dialog.Panel>
			</div>
		</Dialog>
	);
}

export function PageTabList({children}) {
	return (
		<Tab.List as="div" className="rounded shadow grid grid-cols-2">{children}</Tab.List>
	);
}

export function PageTab({children}) {
	return (
		<Tab as="div" className="group focus:outline-none">
			{({selected}) => <PageTabButton selected={selected}>{children}</PageTabButton>}
		</Tab>
	);
}

function PageTabButton({selected, children}) {
	return (
		<Button type="button"
				className={`w-full p-3 rounded-none group-first:rounded-l group-last:rounded-r shadow-none text-slate-600 ${selected ? "!bg-white" : "bg-slate-50 hover:bg-slate-100"}`}>{children}</Button>
	);
}
