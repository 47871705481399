import {useEffect, useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useToken} from "components/token";
import {Button, If, Loading} from "components/ui";
import {Page} from "components/layout";

export function Login() {
    let {isExpired, login} = useToken();

    let navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    let returnUrl = searchParams.get("returnUrl") ?? "/";

    let [error, setError] = useState(false);
    let [disabled, setDisabled] = useState(false);

    useEffect(() => {
        if (!isExpired) {
            let url = {
                pathname: returnUrl
            };
            navigate(url);
        }
    }, []);

    function handleSubmit(emailAddress, password) {
        setDisabled(true);
        login(emailAddress, password)
            .then(({type, token}) => {
                if (type === "" || token === "") {
                    setError(true);
                    setDisabled(false);
                } else {
                    let url = {
                        pathname: returnUrl
                    };
                    navigate(url);
                }
            });
    }

    return (
        <Page title="Login" className="m-auto max-w-lg w-full px-5">
            <Loading condition={isExpired && !disabled} className="w-1/5 max-w-16 mx-auto">
                <div className="grid grid-flow-row-dense gap-5">
                    <div className="flex flex-row items-center gap-5">
                        <img className="w-1/5" src="/logo.png" alt="Manly Campus logo"/>
                        <div className="flex flex-col">
                            <div className="text-xl text-slate-600">Manly Campus</div>
                            <div className="text-sm text-slate-400">Northern Beaches Secondary College</div>
                        </div>
                    </div>
                    <If condition={returnUrl !== "/"}>
                        <span className="text-xs text-slate-500">You will be redirected to <span className="font-bold">{returnUrl}</span></span>
                    </If>
                    <EmailAddressPasswordForm error={error} disabled={disabled} onSubmit={handleSubmit}/>
                </div>
            </Loading>
        </Page>
    );
}

function EmailAddressPasswordForm({error, disabled, onSubmit}) {
    let [emailAddress, setEmailAddress] = useState("");
    let [password, setPassword] = useState("");

    function handleSubmit(e) {
        e.preventDefault();
        onSubmit(emailAddress, password);
    }

    function handleTabKeyDown(e) {
        if (emailAddress.length > 0 && !emailAddress.includes("@")) {
            e.preventDefault();
            setEmailAddress(emailAddress + "@det.nsw.edu.au");
        }
    }

    return (
        <form className="grid grid-flow-row-dense gap-5" onSubmit={handleSubmit}>
            <div className="group bg-white rounded shadow">
                <input name="emailAddress" type="email" autoComplete="email" autoFocus required
                       className="px-3 py-2 w-full bg-transparent focus:outline-none"
                       placeholder="Email address"
                       disabled={disabled}
                       onKeyDown={(e) => (e.key === "Tab") && handleTabKeyDown(e)}
                       value={emailAddress} onChange={(e) => setEmailAddress(e.target.value)}/>
                <input name="password" type="password" required
                       className="px-3 py-2 w-full bg-transparent focus:outline-none"
                       placeholder="Password"
                       disabled={disabled}
                       value={password} onChange={(e) => setPassword(e.target.value)}/>
            </div>
            <If condition={error}>
                <span className="text-xs font-bold text-red-500">Your credentials are incorrect or missing, try again</span>
            </If>
            <Button type="submit" disabled={disabled}
                    className={error && "bg-red-600 hover:bg-red-700"}>LOG-IN</Button>
        </form>
    );
}

export function Logout() {
    let {logout} = useToken();
    let navigate = useNavigate();

    useEffect(() => {
        logout()
            .then(() => {
                let url = {
                    pathname: "/"
                };
                navigate(url);
            });
    }, []);

    return (
        <Page title="Logout">
            <div className="m-auto max-w-lg w-full px-5">
                <Loading className="w-1/5 mx-auto"/>
            </div>
        </Page>
    );
}