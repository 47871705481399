export function download(filename, filetype, contents) {
	let url = window.URL.createObjectURL(
		new Blob([contents], {type: filetype}),
	);

	let link = document.createElement("a");
	link.href = url;
	link.setAttribute("download", filename);

	document.body.appendChild(link);

	link.click();
	link.parentNode.removeChild(link);
}
