import {useLocation} from "react-router-dom";
import {Page} from "components/layout";

export function NotFound() {
	let location = useLocation();
	return (
		<Page title="404 Not Found" className="m-auto max-w-lg w-full px-5 text-center break-words">
			<span className="text-xs text-slate-500">404 Not Found <span className="font-bold">{location.pathname}</span></span>
		</Page>
	);
}