import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {usePopper} from "react-popper";
import {twMerge} from "tailwind-merge";
import {Popover} from "@headlessui/react";
import {PlusIcon, TrashIcon} from "@heroicons/react/24/solid";
import {useApi, useToken} from "components/token";
import {SignInActiveSession, SignInSessionSummary} from "components/sign-in";
import {Button, If, Loading} from "components/ui";
import {
	Page,
	PageNav, PageNavBreadcrumbs, PageNavCrumb, PageNavCrumbLink
} from "components/layout";

export default function SignInUserProfile() {
	let {emailAddress} = useParams();
	let navigate = useNavigate();

	let [user, fetchUser] = useApi();
	let {firstname, lastname} = user ?? {};

	let [session, fetchSession] = useApi();

	let {claims} = useToken();

	useEffect(() => {
		fetchUser("GET", "/user/" + emailAddress)
			.then(() => fetchSession("GET", "/sign-in/session/active/" + emailAddress))
			.catch(() => {
				let url = {
					pathname: "/sign-in"
				};
				navigate(url);
			});
	}, [emailAddress]);

	return (
		<Page title="Timetable">
			<PageNav>
				<PageNavBreadcrumbs>
					<PageNavCrumb title="Sign-in"/>
					<PageNavCrumbLink title="Have you signed in today?" href="/sign-in"/>
					<If condition={firstname && lastname}>
						<PageNavCrumbLink title={`${firstname} ${lastname}`} href={`/sign-in/${emailAddress}`}/>
					</If>
				</PageNavBreadcrumbs>
			</PageNav>
			<Loading condition={session !== undefined} className="w-1/5 max-w-16 mx-auto">
				<SignInActiveSession user={user} session={session ?? {}}/>
			</Loading>
			<If condition={claims.groups.includes("office")}>
				<Loading condition={user} className="w-1/5 max-w-16 mx-auto">
					<SignInStaffTagManagement user={user ?? {}}/>
				</Loading>
			</If>
			<SignInSessionSummary user={user ?? {}}/>
		</Page>
	);
}

function SignInStaffTagManagement({user}) {
	let {emailAddress} = user;
	let [tags, fetchTags] = useApi();
	let [form, submitForm] = useApi();
	let [disabled, setDisabled] = useState(true);

	useEffect(() => {
		if (emailAddress) {
			fetchTags("GET", "/sign-in/tags/" + emailAddress)
				.then(() => setDisabled(false))
		}
	}, [user, form]);

	function handleTagFormSubmit(formData) {
		if (tags.includes(formData["tag"])) {
			setDisabled(true);
			submitForm("DELETE", "/sign-in/tag/" + emailAddress, {body: formData});
		}
	}

	function handleNewTagFormSubmit(formData) {
		if (!tags.includes(formData["tag"])) {
			setDisabled(true);
			submitForm("POST", "/sign-in/tag/" + emailAddress, {body: formData});
		}
	}

	return (
		<div className="p-3 bg-white rounded shadow grid grid-flow-row-dense gap-3">
			<span className="text-xs text-slate-500">To manage tags, click the buttons below</span>
			<Loading condition={!disabled && tags} className="w-1/5 max-w-16 mx-auto">
				<div className="grid grid-cols-6 lg:grid-cols-12 gap-1 items-center">
					<SignInStaffTagForm tags={tags ?? []} onSubmit={handleTagFormSubmit} disabled={disabled}/>
					<SignInStaffNewTagForm onSubmit={handleNewTagFormSubmit} disabled={disabled}/>
				</div>
			</Loading>
		</div>
	);
}

function SignInStaffTagForm({tags, onSubmit}) {
	function handleClick(tag) {
		onSubmit({"tag": tag});
	}

	return tags.map((value, i) =>
		<SignInStaffTag key={i} value={value} onClick={handleClick}/>);
}
function SignInStaffTag({value, disabled, onClick}) {
	return (
		<Button type="button" className="group p-1 text-xs font-medium"
				disabled={disabled} onClick={(e) => onClick(value)}>
			<TrashIcon className="shrink-0 size-4 hidden group-hover:inline"/>
			<span className="truncate inline group-hover:hidden">{value}</span>
		</Button>
	);
}

function SignInStaffNewTagForm({onSubmit, disabled}) {
	let [referenceElement, setReferenceElement] = useState();
	let [popperElement, setPopperElement] = useState();
	let {styles, attributes} = usePopper(referenceElement, popperElement);

	let [value, setValue] = useState("");
	let [error, setError] = useState(true);

	function handleChange(e) {
		setValue(e.target.value);
		setError(!/^[a-zA-Z0-9-_]+$/.test(e.target.value));
	}

	function handleSubmit(e) {
		e.preventDefault();
		if (!error) {
			onSubmit({"tag": value});
		}
	}

	return (
		<Popover>
			{
				({open, close}) => (
					<>
						<Popover.Button ref={setReferenceElement} as="div" className={twMerge("w-fit", open && "invisible")}>
							<Button type="button" className="w-min p-1 text-sm font-medium"
									disabled={disabled}><PlusIcon className="shrink-0 size-6"/></Button>
						</Popover.Button>
						<Popover.Panel ref={setPopperElement} style={styles.popper} className="z-20" {...attributes.popper}>
							<form onSubmit={e => {handleSubmit(e); close();}}
								  className="p-3 bg-white rounded shadow flex flex-row items-center gap-1">
								<input type="text" value={value} onChange={handleChange}
									   className="focus:outline-none p-1 bg-slate-600 rounded text-center text-sm font-medium text-white"/>
								<Button type="submit" className={twMerge("w-min p-1 text-sm font-medium", error && "bg-slate-400 hover:bg-slate-400")}
										disabled={error || disabled}><PlusIcon className="shrink-0 size-6"/></Button>
							</form>
						</Popover.Panel>
					</>
				)
			}
		</Popover>
	);
}
