import {useEffect, useState} from "react";
import {useParams, Link, useNavigate} from "react-router-dom";
import {usePopper} from "react-popper";
import {Popover} from "@headlessui/react";
import {useApi} from "components/token";
import {Timetable} from "components/timetable";
import {If, Loading} from "components/ui";
import {
	Page,
	PageNav, PageNavBreadcrumbs, PageNavCrumb, PageNavCrumbLink
} from "components/layout";

export default function TimetableUserProfile() {
    let {emailAddress} = useParams();
    let navigate = useNavigate();

    let [user, fetchUser] = useApi();
    let {firstname, lastname} = user ?? {};

    let [timetable, fetchTimetable] = useApi();
    let [bellTimes] = useApi("GET", "/timetable/bell-times");
    let [startDate] = useApi("GET", "/timetable/settings/start-date");

    useEffect(() => {
        fetchUser("GET", "/user/" + emailAddress)
            .then(() => fetchTimetable("GET", "/timetable/" + emailAddress))
            .catch(() => {
                let url = {
                    pathname: "/timetable"
                };
                navigate(url);
            });
    }, [emailAddress]);

    return (
        <Page title="Timetable">
			<PageNav>
				<PageNavBreadcrumbs>
					<PageNavCrumb title="Timetable"/>
					<PageNavCrumbLink title="Find a timetable" href="/timetable"/>
					<If condition={firstname && lastname}>
						<PageNavCrumbLink title={`${firstname} ${lastname}`} href={`/timetable/${emailAddress}`}/>
					</If>
				</PageNavBreadcrumbs>
			</PageNav>
            <Loading condition={startDate && bellTimes && timetable} className="w-1/5 max-w-16 mx-auto">
                <Timetable startDate={startDate} bellTimes={bellTimes ?? []} forEach={timetable ?? []}>
                    {tt => <TimetableEntry classCode={tt["ClassCode"]} courseName={tt["CourseName"]}
                                           roomCode={tt["RoomCode"]} startTime={tt["StartTime"]}
                                           backgroundColor={"#" + tt["Colour"]}/>}
                </Timetable>
            </Loading>
        </Page>
    );
}

function TimetableEntry({classCode, courseName, roomCode, startTime, backgroundColor}) {
    let [referenceElement, setReferenceElement] = useState();
    let [popperElement, setPopperElement] = useState();
    let {styles, attributes} = usePopper(referenceElement, popperElement);
    return (
        <Popover>
            <Popover.Button ref={setReferenceElement} className="w-full focus:outline-none">
                <TimetableEntryDetails classCode={classCode} courseName={courseName}
                                       roomCode={roomCode} startTime={startTime}
                                       backgroundColor={backgroundColor}/>
            </Popover.Button>
            <Popover.Panel ref={setPopperElement} style={styles.popper} className="z-10" {...attributes.popper}>
                <TimetableEntryUserList classCode={classCode}/>
            </Popover.Panel>
        </Popover>
    );
}

function TimetableEntryDetails({classCode, courseName, roomCode, startTime, backgroundColor}) {
    let OPACITY = 69; // nice
    return (
        <div className="hover:underline p-1 rounded shadow grid gap-1 break-words text-center text-xs text-slate-600" style={{backgroundColor: backgroundColor + OPACITY}}>
            <span className="p-2 bg-white rounded"><span className="font-bold">{classCode}</span> <span className="text-slate-400">{courseName}</span></span>
            <If condition={roomCode}>
                <span className="p-2 bg-white rounded"><span className="text-slate-400">Room</span> <span className="font-bold">{roomCode}</span></span>
            </If>
        </div>
    );
}

function TimetableEntryUserList({classCode}) {
    let [users] = useApi("GET", "/timetable/class/" + classCode);
    users ??= [];

    let staff = users.filter(({groups}) => groups.includes("staff"));
    let students = users.filter(({groups}) => groups.includes("student"));

    return (
        <div className="w-64 p-3 bg-white rounded shadow grid grid-flow-row gap-1">
            <Loading condition={users.length > 0} className="w-1/5 max-w-16 mx-auto">
                <If condition={staff.length > 0}>
                    <UserList title="Staff" users={staff}/>
                </If>
                <If condition={students.length > 0}>
                    <UserList title="Students" users={students}/>
                </If>
            </Loading>
        </div>
    );
}

function UserList({title, users}) {
    return (
        <div className="grid gap-1 text-slate-600">
            <span className="text-md font-medium">{title}</span>
            <div className="grid grid-cols-2 gap-1">
                {users.map(({firstname, lastname, emailAddress}, i) =>
                    <Link key={i} to={"/timetable/" + emailAddress}
                          className="truncate hover:underline text-xs">{firstname} {lastname}</Link>)}
            </div>
        </div>
    );
}
