import {useContext, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {XCircleIcon} from "@heroicons/react/24/outline";
import {CheckCircleIcon} from "@heroicons/react/24/solid";
import {useApi, useToken} from "components/token";
import {LOCATIONS, SignInActiveSession} from "components/sign-in";
import {
	Button,
	If,
	Loading,
	Option, Select,
	Textarea,
	UserSearch
} from "components/ui";
import {
	modalContext,
	Page, PageModal,
	PageNav, PageNavBreadcrumbs, PageNavCrumb, PageNavCrumbLink
} from "components/layout";

export default function SignInSearch() {
	let {claims} = useToken();
	let {emailAddress} = claims;

	let [session, fetchSession] = useApi("GET", "/sign-in/session/active/" + emailAddress);
	let [sessions, fetchSessions] = useApi("GET", "/sign-in/sessions/active");

	let [open, setOpen] = useState(false);

	function handleSubmit(formData) {
		let method = formData.location ? "POST" : "PUT";
		fetchSession(method, "/sign-in/session", {body: formData})
			.then(() => setTimeout(fetchSessions, 1000));
	}

	return (
		<Page title="Sign-in">
			<PageNav>
				<PageNavBreadcrumbs>
					<PageNavCrumb title="Sign-in"/>
					<PageNavCrumbLink title="Have you signed in today?" href="/sign-in"/>
				</PageNavBreadcrumbs>
				<div className="flex flex-row items-center gap-1 flex-wrap">
					<Button type="button" onClick={(e) => setOpen(true)}>SIGN-IN/OUT</Button>
				</div>
			</PageNav>
			<PageModal open={open} setOpen={setOpen}>
				<Loading condition={session !== undefined} className="w-1/5 max-w-16 mx-auto">
					<SignInStaffForm disabled={session === undefined} onSubmit={handleSubmit}
									 session={session}/>
				</Loading>
			</PageModal>
			<Loading condition={session !== undefined} className="w-1/5 max-w-16 mx-auto">
				<SignInActiveSession session={session ?? {}}/>
			</Loading>
			<SignInStaffSearch sessions={sessions ?? []}/>
		</Page>
	);
}

function SignInStaffForm({disabled, onSubmit, session}) {
	let [, setOpen] = useContext(modalContext);
	let [location, setLocation] = useState(LOCATIONS[0]);
	let [notes, setNotes] = useState("");

	useEffect(() => {
		if (session) {
			setLocation(session.location);
			setNotes(session.notes);
		}
	}, [session]);

	function handleSubmit(e) {
		e.preventDefault();
		onSubmit(session ? {"notes": notes} : {"location": location, "notes": notes});
		setOpen(false);
	}

	return (
		<form onSubmit={handleSubmit} className="space-y-3">
			<p className="text-xs text-slate-500">Select a location to sign-in, e.g., on an excursion choose "OFF-SITE"</p>
			<Select value={location} onChange={setLocation} disabled={disabled || session !== null}>
				{
					LOCATIONS.map((location, i) =>
						<Option key={i} value={location}>{location}</Option>)
				}
			</Select>
			<p className="text-xs text-slate-500">Optionally, enter notes below, e.g., "Swimming Carnival"</p>
			<Textarea value={notes} onChange={(e) => setNotes(e.target.value)}
					  disabled={disabled}/>
			<Button type="submit" disabled={disabled}>{session ? "SIGN-OUT" : "SIGN-IN"}</Button>
		</form>
	);
}

function SignInStaffSearch({sessions}) {
	let activeSessions = Object.fromEntries(sessions.map(s => [s.emailAddress, s]));
	return (
		<UserSearch placeholder="Type here to search" group="staff" limit={100}
					className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 gap-1">
			{
				(user) => {
					let {createdAt} = activeSessions[user.emailAddress] ?? {};
					return (
						<Link to={"/sign-in/" + user.emailAddress}
							  className="group bg-white p-3 rounded shadow hover:shadow-lg z-0 hover:z-10 grid grid-cols-2">
							<div className="flex flex-row items-center gap-1">
								{createdAt ? <CheckCircleIcon className="w-4 h-4"/> : <XCircleIcon className="w-4 h-4"/>}
								<div className="text-left text-xs group-hover:font-bold truncate">{user.firstname} {user.lastname}</div>
							</div>
							<If condition={createdAt}>
								<div className="text-right text-xs text-slate-500 truncate">Signed-in {createdAt}</div>
							</If>
						</Link>
					);
				}
			}
		</UserSearch>
	);
}
