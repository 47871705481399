import {useEffect, useState} from "react";
import {CheckCircleIcon, ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/24/solid";
import {XCircleIcon} from "@heroicons/react/24/outline";
import {useApi, useToken} from "components/token";
import {download} from "util/download";
import {Button, If, Loading} from "components/ui";

export const LOCATIONS = ["ON-SITE", "OFF-SITE"];

export function SignInActiveSession({user, session}) {
	let {claims} = useToken();
	let {firstname, lastname} = user ?? claims;
	let {location, createdAt} = session ?? {};
	return (
		<div className="bg-white p-3 rounded shadow flex gap-1 justify-between">
			<div className="flex flex-row items-center gap-1">
				{createdAt ? <CheckCircleIcon className="w-4 h-4"/> : <XCircleIcon className="w-4 h-4"/>}
				<div className="text-left text-xs group-hover:font-bold"><span className="font-bold">{user ? `${firstname} ${lastname}` : "You"}</span> {user ? "is" : "are"} currently <span className="font-bold uppercase">{location ?? "signed-out"}</span></div>
			</div>
			<If condition={createdAt}>
				<div className="text-right text-xs text-slate-500 truncate">Signed-in {createdAt}</div>
			</If>
		</div>
	);
}

function toLocaleDateString(date, locales, options) {
	return date.toLocaleDateString(locales, options)
		.split("/").reverse().join("-");
}

function today() {
	let d = new Date();
	return toLocaleDateString(d, "en-AU");
}

function tomorrow() {
	let d = new Date();
	d.setDate(d.getDate() + 1);
	return toLocaleDateString(d, "en-AU");
}

export function SignInSessionSummary({user, pageSize=50}) {
	let [notBefore, setNotBefore] = useState(today());
	let [notAfter, setNotAfter] = useState(tomorrow());

	let [results, fetchResults] = useApi();
	let sessions = results ?? [];

	let [page, setPage] = useState(0);

	useEffect(() => {
		let path = `/sign-in/sessions/${notBefore}/${notAfter}`;
		if (!user) {
			fetchResults("GET", path);
		} else {
			let {emailAddress} = user;
			if (emailAddress) {
				path += `/${emailAddress}`;
				fetchResults("GET", path);
			}
		}
	}, [user, notBefore, notAfter]);

	return (
		<div className="p-3 bg-white rounded shadow grid grid-flow-row-dense gap-3">
			<div className="flex flex-row flex-wrap justify-between gap-3">
				<div className="flex flex-row flex-wrap gap-3">
					<input type="date" value={notBefore} onChange={(e) => setNotBefore(e.target.value)}
						   className="focus:outline-none p-3 border border-slate-300 rounded text-xs text-slate-600"/>
					<input type="date" value={notAfter} onChange={(e) => setNotAfter(e.target.value)}
						   className="focus:outline-none p-3 border border-slate-300 rounded text-xs text-slate-600"/>
				</div>
				<div className="flex flex-row flex-wrap items-center gap-3">
					<If condition={sessions.length > pageSize}>
						<SignInSessionSummaryPageControl totalSessions={sessions.length}
														 page={page} setPage={setPage} pageSize={pageSize}/>
					</If>
					<SignInSessionSummaryDownloadButton filename={`${notBefore}-${notAfter}.csv`} sessions={sessions}/>
				</div>
			</div>
			<SignInSessionAnalytics sessions={sessions}/>
			<span className="text-xs text-slate-500">You are seeing {sessions.length} results, between {notBefore} and {notAfter}</span>
			<Loading condition={results} className="w-1/5 max-w-16 mx-auto">
				<SignInSessionSummaryTable sessions={sessions.slice(page*pageSize, (1+page)*pageSize)}/>
			</Loading>
		</div>
	);
}

function SignInSessionSummaryPageControl({totalSessions, page, setPage, pageSize}) {
	let maxPage = Math.max(0, Math.ceil(totalSessions/pageSize)-1);
	let tryPage = p => setPage(Math.min(maxPage, Math.max(0, p)));
	return (
		<div className="p-1 flex flex-row items-center gap-3">
			<Button type="button" className="p-1 size-min"
					onClick={() => tryPage(page-1)}>
				<ChevronLeftIcon className="size-6 text-white"/>
			</Button>
			<span className="text-xs font-medium text-slate-600">Page {page+1}/{maxPage+1}</span>
			<Button type="button" className="p-1 size-min"
					onClick={() => tryPage(page+1)}>
				<ChevronRightIcon className="w-6 h-6 text-white"/>
			</Button>
		</div>
	)
}

function SignInSessionSummaryDownloadButton({filename, sessions}) {
	function handleClick() {
		let filetype = "text/csv";

		let headers = ["Email address", "Location", "Signed-in", "Signed-out", "Notes"];
		let rows = sessions.map(({emailAddress, location, createdAt, endedAt, notes}) =>
			[emailAddress, location, createdAt, endedAt, notes]);

		let contents = [headers, ...rows];
		download(filename, filetype, contents.join("\n"));
	}

	return <Button type="button" onClick={handleClick} className="h-min">DOWNLOAD</Button>;
}

function SignInSessionAnalytics({sessions}) {
	let firstSessionsPerUserPerDay =
		Object.entries(Object.groupBy(sessions, s => s.emailAddress + s.createdAt.split(" ")[0]))
			.map(([_, s]) => s.reduce((s1, s2) => s1.createdAt < s2.createdAt ? s1 : s2));

	let before10am = s => s.createdAt.split(" ")[1] < "10:00:00";

	let locationONSITE = s => s.location === "ON-SITE"

	return (
		<div className="grid grid-cols-2 gap-1">
			<SignInSessionSummaryAnalyticsProportionScore
				title="Signed-in before 10:00 AM" sessions={firstSessionsPerUserPerDay}
				condition={before10am}/>
			<SignInSessionSummaryAnalyticsProportionScore
				title="Location ON-SITE" sessions={sessions}
				condition={locationONSITE}/>
		</div>
	);
}

function SignInSessionSummaryAnalyticsProportionScore({title, sessions, condition}) {
	let filteredSessions = sessions.filter(condition);
	let percent = sessions.length > 0 ? filteredSessions.length/sessions.length : 0;
	let percent100 = (100*percent).toFixed(2);

	return (
		<SignInSessionSummaryAnalyticsProgressBar title={title} percent={percent}
			text={`${filteredSessions.length}/${sessions.length} (${percent100}%)`}/>
	);
}

function SignInSessionSummaryAnalyticsProgressBar({title, percent, text}) {
	return (
		<div>
			<span className="text-xs text-slate-500">{title}</span>
			<div className="w-100 flex flex-row items-center">
				<div className="grow bg-slate-200 rounded h-2.5 me-2">
					<div className="bg-slate-600 h-2.5 rounded" style={{width: 100*percent + "%"}}></div>
				</div>
				<If condition={text !== undefined}>
					<span className="text-xs text-slate-500">{text}</span>
				</If>
			</div>
		</div>
	);
}

function SignInSessionSummaryTable({sessions}) {
	return (
		<div className="overflow-auto max-h-[64rem]">
			<table className="min-w-[48rem] w-full whitespace-nowrap table-fixed text-left text-xs text-slate-600">
				<thead>
				<tr>
					<th>Email address</th>
					<th>Location</th>
					<th>Signed-in</th>
					<th>Signed-out</th>
					<th>Notes</th>
				</tr>
				</thead>
				<tbody>
					{sessions.map((s, i) => <SignInSessionSummaryTableRow key={i} session={s}/>)}
				</tbody>
			</table>
		</div>
	);
}

function SignInSessionSummaryTableRow({session}) {
	let {emailAddress, location, createdAt, endedAt, notes} = session;
	return (
		<tr className="odd:bg-gray-100 text-xs text-slate-600">
			<td className="truncate">{emailAddress}</td>
			<td>{location}</td>
			<td>{createdAt}</td>
			<td>{endedAt}</td>
			<td className="whitespace-normal">{notes ? notes : <span className="italic text-xs">Notes not provided</span>}</td>
		</tr>
	);
}
