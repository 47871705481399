import {createContext, useContext, useEffect, useState} from "react";
import {useJwt} from "react-jwt";

export const API_URL = process.env.REACT_APP_API_URL;

let tokenContext = createContext();
export function ProvideToken({children}) {
	let token = useProvideToken();
	return (
		<tokenContext.Provider value={token}>
			{children}
		</tokenContext.Provider>
	);
}

export function useToken() {
	return useContext(tokenContext);
}

function useProvideToken() {
	let [type, setType] = useState(localStorage["type"] ?? "");
	let [token, setToken] = useState(localStorage["token"] ?? "");
	let {decodedToken, isExpired, reEvaluateToken} = useJwt(token);

	// expired if empty token
	isExpired ||= (type === "" || token === "");
	// empty token if expired
	if (isExpired && !(type === "" && token === "")) {
		logout();
	}

	function set({type, token}) {
		localStorage["type"] = type;
		localStorage["token"] = token;
		setType(type);
		setToken(token);
		reEvaluateToken(token);
	}

	function login(emailAddress, password) {
		let formData = {
			"emailAddress": emailAddress,
			"password": password
		};
		let options = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"Accept": "application/json"
			},
			body: JSON.stringify(formData)
		};
		let url = API_URL + "/token";
		return fetch(url, options)
			.then((res) => res.ok ? res.json() : {type: "", token: ""})
			.then(({type, token}) => {
				set({type, token});
				return {type, token};
			})
	}

	function api(method, path, options={}) {
		let {body} = options;
		reEvaluateToken(token);

		if (isExpired) {
			return logout();
		}

		let init = {
			method: method,
			headers: {
				"Authorization": `${type} ${token}`,
				"Accept": "application/json"
			},
		}

		if (body !== undefined) {
			init.headers["Content-Type"] = "application/json";
			init.body = JSON.stringify(body);
		}

		let url = API_URL + path;
		return fetch(url, init)
			.then((res) => {
				if (res.ok) {
					let contentType = res.headers.get("Content-Type");
					return contentType === "application/json" ? res.json() : res.text()
				} else {
					throw res;
				}
			});
	}

	function logout() {
		set({type: "", token: ""});
		return Promise.resolve();
	}

	return {
		type,
		token,
		isExpired,
		claims: decodedToken ?? {},
		login,
		api,
		logout
	};
}

export function useApi(method=undefined, path=undefined, options={}) {
	let {body} = options;
	let {api} = useToken();
	let [cache, setCache] = useState({method, path, body});
	let [result, setResult] = useState(undefined);

	function fetchResult(method=cache.method, path=cache.path, options={}) {
		let {body} = options;
		if (method !== cache.method || path !== cache.path || body !== cache.body) {
			setCache({method, path, body});
		}

		setResult(undefined);

		return api(method, path, {body})
			.then((res) => {
				setResult(res);
				return res;
			});
	}

	useEffect(() => {
		if (cache.method !== undefined && cache.path !== undefined) {
			fetchResult(cache.method, cache.path, cache.body);
		}
	}, []);

	return [result, fetchResult];
}

