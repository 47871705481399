import React from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import {Layout, NavigationLayout, ProtectedLayout} from "components/layout";
import {ProvideToken} from "components/token";
import {NotFound} from "routes/4xx";
import {AwardsDashboard, AwardsAdminSearch, AwardsAdminUserProfile, AwardsAdminTemplates} from "routes/awards";
import {DirectorySearch, DirectoryUserProfile} from "routes/directory";
import {Homepage} from "routes/homepage";
import {Login, Logout} from "routes/login";
import {SignInSearch, SignInUserProfile, SignInAdmin} from "routes/sign-in";
import {TimetableSearch, TimetableUserProfile, TimetableBellTimes} from "routes/timetable";
import "index.css";

if (typeof window.Object.groupBy === "undefined") {
	window.Object.groupBy = (x,f)=>x.reduce((a,b,i)=>((a[f(b,i,x)]||=[]).push(b),a),{});
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<ProvideToken>
			<Router>
				<Routes>
					<Route element={<Layout/>}>
						<Route exact path="*" element={<NotFound/>}/>
						<Route path="login" element={<Login/>}/>
						<Route path="logout" element={<Logout/>}/>

						<Route element={<NavigationLayout/>}>
							<Route index element={<Homepage/>}/>

							<Route element={<ProtectedLayout/>}>
								<Route path="directory" element={<DirectorySearch/>}/>
								<Route path="directory/:emailAddress" element={<DirectoryUserProfile/>}/>

								<Route element={<ProtectedLayout group="staff"/>}>
									<Route path="sign-in" element={<SignInSearch/>}/>
									<Route path="sign-in/:emailAddress" element={<SignInUserProfile/>}/>
								</Route>
								<Route element={<ProtectedLayout group="office"/>}>
									<Route path="sign-in/admin" element={<SignInAdmin/>}/>
								</Route>

								<Route path="timetable" element={<TimetableSearch/>}/>
								<Route path="timetable/bell-times" element={<TimetableBellTimes/>}/>
								<Route path="timetable/:emailAddress" element={<TimetableUserProfile/>}/>

								<Route path="awards" element={<AwardsDashboard/>}/>
								<Route element={<ProtectedLayout group="office"/>}>
									<Route path="awards/admin" element={<AwardsAdminSearch/>}/>
									<Route path="awards/admin/templates" element={<AwardsAdminTemplates/>}/>
									<Route path="awards/admin/:emailAddress" element={<AwardsAdminUserProfile/>}/>
								</Route>
							</Route>
						</Route>
					</Route>
				</Routes>
			</Router>
		</ProvideToken>
	</React.StrictMode>
);
