import {useEffect} from "react";
import {useParams, Link, useNavigate} from "react-router-dom";
import {ArrowTopRightOnSquareIcon} from "@heroicons/react/24/solid";
import {useApi, useToken} from "components/token";

import {If, Loading} from "components/ui";
import {
	Page,
	PageNav, PageNavBreadcrumbs, PageNavCrumb, PageNavCrumbLink
} from "components/layout";

export default function DirectoryUserProfile() {
	let {emailAddress} = useParams();
	let navigate = useNavigate();

	let [user, fetchUser] = useApi();
	let {firstname, lastname, groups} = user ?? {};

	let {claims} = useToken();

	useEffect(() => {
		fetchUser("GET", "/user/" + emailAddress)
			.catch(() => {
				let url = {
					pathname: "/directory"
				};
				navigate(url);
			});
	}, [emailAddress]);

	return (
		<Page title="Directory">
			<PageNav>
				<PageNavBreadcrumbs>
					<PageNavCrumb title="Directory"/>
					<PageNavCrumbLink title="Find a user" href="/directory"/>
					<If condition={firstname && lastname}>
						<PageNavCrumbLink title={`${firstname} ${lastname}`} href={`/directory/${emailAddress}`}/>
					</If>
				</PageNavBreadcrumbs>
			</PageNav>
			<div className="grid grid-cols-1 xl:grid-cols-2 gap-1">
				<Loading condition={groups !== undefined} className="w-1/5 max-w-16 mx-auto">
					<UserProfileSummary firstname={firstname ?? ""} lastname={lastname ?? ""}
												  emailAddress={emailAddress} groups={groups ?? []}/>
				</Loading>
				<div className="flex flex-col gap-1">
					<UserProfileLink title="Timetable" href={"/timetable/" + emailAddress}/>
					<If condition={claims.groups.includes("staff") && groups && !groups.includes("student")}>
						<UserProfileLink title="Sign-In" href={"/sign-in/" + emailAddress}/>
					</If>
					<If condition={claims.groups.includes("office")}>
						<UserProfileLink title="Awards" href={"/awards/admin/" + emailAddress}/>
					</If>
				</div>
			</div>
		</Page>
	);
}

function UserProfileSummary({firstname, lastname, emailAddress, groups}) {
	return (
		<div className="h-min p-3 bg-white rounded shadow flex flex-col gap-1">
			<div className="text-xs text-slate-600 font-bold">Directory</div>
			<div className="flex flex-row flex-wrap gap-1">
				<span className="text-slate-600">{firstname} {lastname}</span>
				<span className="truncate text-slate-600">
					<span className="text-slate-400 select-none">&lt;</span>
					{emailAddress}
					<span className="text-slate-400 select-none">&gt;</span>
				</span>
			</div>
			<div className="flex flex-row gap-1 flex-wrap">
				{
					groups.map((g, i) => (
						<div key={i} className="text-xs text-white font-bold p-1 bg-slate-600 rounded">
							<span className="uppercase">{g}</span>
						</div>
					))
				}
			</div>
		</div>
	);
}

function UserProfileLink({title, href}) {
	return (
		<div className="h-min p-3 bg-white rounded shadow flex flex-col gap-1">
			<div className="text-xs text-slate-600 font-bold">{title}</div>
			<div className="grid">
				<Link to={href} className="truncate space-x-1 hover:underline">
					<ArrowTopRightOnSquareIcon className="size-4 inline"/>
					<span className="text-slate-600">{href}</span>
				</Link>
			</div>
		</div>
	);
}
