import {createContext, useContext, useState} from "react";
import {PencilSquareIcon, TrashIcon} from "@heroicons/react/24/solid";
import {useApi} from "components/token";
import {TemplateSearch} from "components/awards";
import {Button, Loading, Option, Select, Textarea} from "components/ui";
import {
	Page, PageModal,
	PageNav, PageNavBreadcrumbs, PageNavCrumb, PageNavCrumbLink
} from "components/layout";

const formContext = createContext();

export default function AwardsAdminTemplates() {
	let [templates, fetchTemplates] = useApi("GET", "/awards/templates");
	let [template, setTemplate] = useState();

	let [newFormOpen, setNewFormOpen] = useState(false);
	let [updateFormOpen, setUpdateFormOpen] = useState(false);
	let [deleteFormOpen, setDeleteFormOpen] = useState(false);

	let [submit, setSubmit] = useState(false);
	let [, submitForm] = useApi();

	function handleNewFormSubmit(formData) {
		setSubmit(true);
		submitForm("POST", "/awards/template", {body: formData})
			.then(() => fetchTemplates())
			.then(() => setNewFormOpen(false))
			.then(() => setSubmit(false));
	}

	function handleUpdateFormSubmit(templateId, formData) {
		setSubmit(true);
		submitForm("PUT", "/awards/template/" + templateId, {body: formData})
			.then(() => fetchTemplates())
			.then(() => setUpdateFormOpen(false))
			.then(() => setSubmit(false));
	}

	function handleDeleteFormSubmit(templateId) {
		setSubmit(true);
		submitForm("DELETE", "/awards/template/" + templateId)
			.then(() => fetchTemplates())
			.then(() => setDeleteFormOpen(false))
			.then(() => setSubmit(false));
	}

	return (
		<Page title="Awards">
			<PageNav>
				<PageNavBreadcrumbs>
					<PageNavCrumb title="Awards"/>
					<PageNavCrumbLink title="Manage award templates" href="/awards/admin/templates"/>
				</PageNavBreadcrumbs>
				<div className="flex flex-row items-center gap-1 flex-wrap">
					<Button type="button" onClick={() => setNewFormOpen(true)}>NEW AWARD TEMPLATE</Button>
				</div>
			</PageNav>
			<PageModal open={newFormOpen} setOpen={setNewFormOpen}>
				<Loading condition={!submit} className="w-1/5 max-w-16 mx-auto">
					<AwardsAdminNewUpdateTemplateForm onSubmit={handleNewFormSubmit}/>
				</Loading>
			</PageModal>
			<PageModal open={updateFormOpen} setOpen={setUpdateFormOpen}>
				<Loading condition={!submit} className="w-1/5 max-w-16 mx-auto">
					<AwardsAdminNewUpdateTemplateForm template={template} onSubmit={handleUpdateFormSubmit}/>
				</Loading>
			</PageModal>
			<PageModal open={deleteFormOpen} setOpen={setDeleteFormOpen}>
				<Loading condition={!submit} className="w-1/5 max-w-16 mx-auto">
					<AwardsAdminDeleteTemplateForm template={template} onSubmit={handleDeleteFormSubmit}/>
				</Loading>
			</PageModal>
			<formContext.Provider value={[[template, setTemplate], setNewFormOpen, setUpdateFormOpen, setDeleteFormOpen]}>
				<TemplateSearch templates={templates ?? []} limit={100} placeholder="Type here to search"
										   className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 gap-1">
					{(template) => <AwardAdminTemplatesSearchTemplate template={template}/>}
				</TemplateSearch>
			</formContext.Provider>
		</Page>
	);
}

function AwardsAdminNewUpdateTemplateForm({template, onSubmit}) {
	let [displayName, setDisplayName] = useState(template ? template.displayName : "");
	let [description, setDescription] = useState(template ? template.description : "");
	let [groups, setGroups] = useState(template ? template.groups : []);

	let [allGroups] = useApi("GET", "/groups");
	allGroups ??= [];

	function handleSubmit(e) {
		e.preventDefault();
		let formData = {"displayName": displayName, "description": description, "groups": groups};
		if (template) {
			onSubmit(template.templateId, formData);
		} else {
			onSubmit(formData);
		}
	}

	return (
		<form onSubmit={handleSubmit} className="space-y-3">
			<p className="text-xs text-slate-500">Enter a title for the new award template, e.g., "Duke of Edinburgh"</p>
			<input required value={displayName} maxLength="128" onChange={e => setDisplayName(e.target.value)}
				   className="focus:outline-none px-3 py-2 w-full rounded border border-slate-300"/>
			<p className="text-xs text-slate-500">Enter a description for the new award template, e.g., "Granted to students who were declared the 'Age Champion' at a school carnival."</p>
			<Textarea required value={description} maxLength="256" onChange={e => setDescription(e.target.value)}/>
			<p className="text-xs text-slate-500">Select one or more groups to grant access to the award template, e.g., "STAFF"</p>
			<Select value={groups} onChange={setGroups} placeholder="Click here to select one or more options" multiple>
				{
					allGroups.map((g, i) =>
						<Option key={i} value={g}>{g.toUpperCase()}</Option>)
				}
			</Select>
			<Button type="submit">{template ? "UPDATE" : "NEW"}</Button>
		</form>
	);
}

function AwardsAdminDeleteTemplateForm({template, onSubmit}) {
	function handleSubmit(e) {
		e.preventDefault();
		onSubmit(template.templateId);
	}

	return (
		<form onSubmit={handleSubmit} className="space-y-3">
			<p className="text-xs text-slate-500"><span className="font-bold">Warning</span>, deleting an award template will delete all awards created with the award template. <span className="font-bold">This action cannot be reverted.</span></p>
			<p className="text-xs text-slate-500">If you instead want to prevent further awards from being created with this template, instead <span className="font-bold">EDIT</span> the award template to have no assigned groups.</p>
			<Button type="submit">DELETE</Button>
		</form>
	);
}

function AwardAdminTemplatesSearchTemplate({template}) {
	let [[,setTemplate],, setUpdateFormOpen, setDeleteFormOpen] = useContext(formContext);

	function handleClick(setOpen) {
		setTemplate(template);
		setOpen(true);
	}

	return (
		<div className="group bg-white p-3 rounded shadow hover:shadow-lg z-0 hover:z-10 grid grid-flow-col-dense sm:grid-cols-3 gap-1 items-center">
			<abbr title={template.displayName}
				  className="no-underline text-xs group-hover:font-bold text-slate-600 truncate">{template.displayName}</abbr>
			<abbr title={template.description}
				  className="no-underline max-sm:hidden text-xs text-slate-400 truncate">{template.description}</abbr>
			<div className="flex flex-row justify-end gap-1">
				<Button type="button" onClick={() => handleClick(setUpdateFormOpen)}>
					EDIT
				</Button>
				<Button type="button" onClick={() => handleClick(setDeleteFormOpen)}>
					DELETE
				</Button>
			</div>
		</div>
	);
}
