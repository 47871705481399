import {createContext, useContext, useState} from "react";
import {Tab} from "@headlessui/react";
import {useApi, useToken} from "components/token";
import {
    AwardRequestLog,
    AwardRequestSearch,
    AwardRequestSearchResult,
    AwardSearch
} from "components/awards";
import {
    Button,
    If,
    Loading,
    Option, Select,
    Textarea
} from "components/ui";
import {
	Page, PageModal,
	PageNav, PageNavBreadcrumbs, PageNavCrumb, PageNavCrumbLink,
	PageTab, PageTabList
} from "components/layout";

const requestContext = createContext();

export default function AwardsDashboard() {
    let {claims} = useToken();
    let groups = claims.groups ?? [];

    let [decidedRequests, fetchDecidedRequests] = useApi("GET", "/awards/requests/decided");
    let [undecidedRequests, fetchUndecidedRequests] = useApi("GET", "/awards/requests/undecided");
    let [request, setRequest] = useState();

    let [newFormOpen, setNewFormOpen] = useState(false);
    let [updateFormOpen, setUpdateFormOpen] = useState(false);

    let [submit, setSubmit] = useState(false);
    let [, submitForm] = useApi();

    function handleNewFormSubmit(formData) {
        setSubmit(true);
        submitForm("POST", "/awards/request", {body: formData})
            .then(req => submitForm("POST", `/awards/request/${req.requestId}/log`, {body: formData})
                .then(() => fetchUndecidedRequests())
                .then((reqs) => setRequest(reqs.find(r => r.requestId === req.requestId))))
            .then(() => setNewFormOpen(false))
            .then(() => setSubmit(false))
            .then(() => setUpdateFormOpen(true));
    }

    function handleUpdateFormSubmit(requestId, formData) {
        setSubmit(true);
        submitForm("PUT", `/awards/request/${requestId}`, {body: formData})
            .then(() => fetchUndecidedRequests())
            .then(() => fetchDecidedRequests())
            .then((reqs) => setRequest(reqs.find(r => r.requestId === requestId)))
            .then(() => setSubmit(false));
    }

    return (
        <Page title="Awards">
			<PageNav>
				<PageNavBreadcrumbs>
					<PageNavCrumb title="Awards"/>
					<PageNavCrumbLink title="View your awards/requests" href="/awards"/>
				</PageNavBreadcrumbs>
				<div className="flex flex-row items-center gap-1 flex-wrap">
	                <Button type="button" onClick={() => setNewFormOpen(true)}>NEW AWARD</Button>
				</div>
			</PageNav>
            <PageModal open={newFormOpen} setOpen={setNewFormOpen}>
                <Loading condition={!submit} className="w-1/5 max-w-16 mx-auto">
                    <AwardsDashboardNewAwardRequestForm onSubmit={handleNewFormSubmit}/>
                </Loading>
            </PageModal>
            <PageModal open={updateFormOpen} setOpen={setUpdateFormOpen}>
                <Loading condition={!submit} className="w-1/5 max-w-16 mx-auto">
                    <AwardsDashboardUpdateAwardRequestForm awardRequest={request} onSubmit={handleUpdateFormSubmit}/>
                </Loading>
            </PageModal>
            <Loading condition={groups.length > 0} className="w-1/5 max-w-16 mx-auto">
                <Tab.Group defaultIndex={1}>
                    <If condition={groups.includes("student")}>
                        <PageTabList>
                            <PageTab>AWARDS</PageTab>
                            <PageTab>AWARD REQUESTS</PageTab>
                        </PageTabList>
                    </If>
                    <Tab.Panels>
                        <Tab.Panel>
                            <AwardsDashboardAwardsTabPanel/>
                        </Tab.Panel>
                        <Tab.Panel>
                            <requestContext.Provider value={[[request, setRequest], [newFormOpen, setNewFormOpen], [updateFormOpen, setUpdateFormOpen]]}>
                                <AwardsDashboardAwardRequestsTabPanel undecidedAwardRequests={undecidedRequests}
                                                                      decidedAwardRequests={decidedRequests}/>
                            </requestContext.Provider>
                        </Tab.Panel>
                    </Tab.Panels>
                </Tab.Group>
            </Loading>
        </Page>
    );
}

function AwardsDashboardNewAwardRequestForm({onSubmit}) {
    let {claims} = useToken();
    let groups = claims.groups ?? [];

    let users = [];
    let [students] = groups.includes("staff") ? useApi("GET", "/group/student") : [];
    users.push.apply(users, students ?? []);
    let [staff] = groups.includes("student") ? useApi("GET", "/group/staff") : [];
    users.push.apply(users, staff ?? []);

    let [templates] = useApi("GET", "/awards/templates/available");
    templates ??= [];

    let [templateId, setTemplateId] = useState(null);
    let [emailAddressOrRecipients, setEmailAddressOrRecipients] = useState([]);
    let [text, setText] = useState("");

    function handleSubmit(e) {
        e.preventDefault();
        onSubmit({
            "templateId": templateId,
            [groups.includes("student") ? "emailAddress" : "recipients"]: emailAddressOrRecipients,
            "text": text
        });
    }

    return (
        <form onSubmit={handleSubmit} className="space-y-3">
            <p className="text-xs text-slate-500">Select an award template e.g., "Performance in Jazz Orchestra"</p>
            <Select search value={templateId} onChange={setTemplateId} placeholder="Click here to select one or more options">
                {
                    templates.map((t, i) =>
                        <Option key={i} value={t.templateId}>{t.displayName}</Option>)
                }
            </Select>
            <p className="text-xs text-slate-500">Enter a justification e.g., "'Age Champion' at a school carnival"</p>
            <Textarea required value={text} maxLength="256" onChange={(e) => setText(e.target.value)}/>
            <p className="text-xs text-slate-500">{groups.includes('student') ? "Select a member of staff to submit the award request" : "Select one or more students to grant the award"}</p>
            <Select search limit={100} value={emailAddressOrRecipients} onChange={setEmailAddressOrRecipients} placeholder={`Click here to select ${groups.includes('student') ? 'a staff member' : 'one or more students'}`} multiple={!groups.includes('student')}>
                {
                    users.map((u, i) =>
                        <Option key={i} value={u.emailAddress}>{getUserDisplayName(u)}</Option>)
                }
            </Select>
            <Button type="submit">SUBMIT</Button>
        </form>
    );
}

function getUserEmailAddress(user) {
    if (typeof user === "string") {
        return user;
    }
    let {emailAddress} = user;
    return emailAddress;
}

function getUserDisplayName(user) {
    if (typeof user === "string") {
        return user;
    }
    let {firstname, lastname} = user;
    return `${firstname} ${lastname}`;
}

function AwardsDashboardUpdateAwardRequestForm({awardRequest, onSubmit}) {
    let {claims} = useToken();
    let groups = claims.groups ?? [];

    let template = awardRequest.template;
    let awardedBy = awardRequest.user ? `${awardRequest.user.firstname} ${awardRequest.user.lastname}` : awardRequest.emailAddress;
    let recipients = awardRequest.recipients.map(r => getUserDisplayName(r));

    let [state, setState] = useState();

    function handleSubmit(e) {
        e.preventDefault();
        let {requestId, templateId, recipients} = awardRequest;
        onSubmit(requestId, {
            "templateId": templateId,
            "state": state,
            "recipients": recipients.map(r => getUserEmailAddress(r))
        });
    }

    return (
        <div className="grid grid-flow-row-dense gap-3">
            <span className="text-base text-slate-600 font-medium">{template.displayName}</span>
            <span className="text-xs text-slate-500">{awardRequest.createdAt}</span>
            <If condition={state}>
                <div className="text-slate-600 text-xs">
                    <If condition={state === "ACCEPTED"}>
                        Are you sure you want to <span className="inline-block p-1 rounded bg-green-600 text-white font-bold">ACCEPT</span> the award request? If <span className="inline-block p-1 rounded bg-green-600 text-white font-bold">ACCEPTED</span> an award will be generated and granted to each recipient
                    </If>
                    <If condition={state === "REJECTED"}>
                        Are you sure you want to <span className="inline-block p-1 rounded bg-red-600 text-white font-bold">REJECT</span> the award request? If <span className="inline-block p-1 rounded bg-red-600 text-white font-bold">REJECTED</span> no award will be generated
                    </If>
                </div>
                <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-1">
                    <Button type="submit" className={state === "ACCEPTED" ? "bg-green-600 hover:bg-green-700" : "bg-red-600 hover:bg-red-700"}>
                        {state === "ACCEPTED" ? "ACCEPT" : "REJECT"}
                    </Button>
                    <Button type="button" onClick={() => setState()}>CANCEL</Button>
                </form>
            </If>
            <If condition={!state}>
                <If condition={groups.includes("staff") && awardRequest.state === "UNDECIDED"}>
                    <div className="text-slate-600 text-xs">
                        <span className="inline-block p-1 rounded bg-green-600 text-white font-bold">ACCEPT</span> or <span className="inline-block p-1 rounded bg-red-600 text-white font-bold">REJECT</span> the award request by clicking one of the following buttons.
                    </div>
                    <div className="grid grid-cols-2 gap-1">
                        <Button type="button" className="bg-green-600 hover:bg-green-700"
                                onClick={() => setState("ACCEPTED")}>ACCEPT</Button>
                        <Button type="button" className="bg-red-600 hover:bg-red-700"
                                onClick={() => setState("REJECTED")}>REJECT</Button>
                    </div>
                </If>
                <div className="space-x-1 text-xs text-slate-600">
                    <If condition={awardRequest.state !== "UNDECIDED"}>
                        <span className={`inline-block p-1 rounded ${awardRequest.state === "ACCEPTED" ? "bg-green-600" : "bg-red-600"} text-white font-bold capitalize`}>{awardRequest.state}</span>
                        <span>by</span>
                    </If>
                    <If condition={awardRequest.state === "UNDECIDED"}>
                        <span>Requested to</span>
                    </If>
                    <span className="font-medium">{awardedBy}</span>
                </div>
                <div className="space-x-1 text-xs text-slate-600">
                    <span>{recipients.length > 1 ? "Recipients" : "Recipient"}</span>
                    <span className="font-medium">{recipients.join(", ")}</span>
                </div>
                <AwardRequestLog editable awardRequest={awardRequest}/>
            </If>
        </div>
    );
}

function AwardsDashboardAwardRequestsTabPanel({undecidedAwardRequests, decidedAwardRequests}) {
    let awardRequests = [];
    awardRequests.push.apply(awardRequests, undecidedAwardRequests);
    awardRequests.push.apply(awardRequests, decidedAwardRequests);
    return (
        <AwardRequestSearch placeholder="Type here to search" className="grid grid-cols-1 2xl:grid-cols-2 gap-1"
                                            awardRequests={awardRequests} limit={100}>
            {(request) => <AwardsDashboardAwardRequestsSearchAwardRequest awardRequest={request}/>}
        </AwardRequestSearch>
    );
}

function AwardsDashboardAwardRequestsSearchAwardRequest({awardRequest}) {
    let [[, setRequest],, [,setUpdateFormOpen]] = useContext(requestContext);

    function handleClick() {
        setRequest(awardRequest);
        setUpdateFormOpen(true);
    }

    return (
        <AwardRequestSearchResult awardRequest={awardRequest} onClick={handleClick}/>
    );
}

function AwardsDashboardAwardsTabPanel() {
    let [awards] = useApi("GET", "/awards");
    return (
        <AwardSearch awards={awards ?? []}
                     placeholder="Type here to search" className="grid grid-cols-1 2xl:grid-cols-2 gap-1">
            {(award) => <AwardsDashboardAwardsSearchAward award={award}/>}
        </AwardSearch>
    );
}

function AwardsDashboardAwardsSearchAward({award}) {
    let template = award.template;
    return (
        <div className="group bg-white p-3 rounded shadow hover:shadow-lg z-0 hover:z-10 grid grid-flow-row-dense gap-1 cursor-pointer">
            <div className="grid grid-flow-col-dense">
                <abbr title={template.displayName}
                      className="no-underline text-xs group-hover:font-bold text-slate-600 max-sm:truncate">{template.displayName}</abbr>
                <span className="text-right text-xs text-slate-500 truncate">{award.createdAt}</span>
            </div>
            <abbr title={template.description}
                  className="no-underline text-xs text-slate-400">{template.description}</abbr>
        </div>
    );
}
