import {Link} from "react-router-dom";
import {UserSearch} from "components/ui";
import {
	Page,
	PageNav, PageNavBreadcrumbs, PageNavCrumb, PageNavCrumbLink
} from "components/layout";

export default function AwardsAdminSearch() {
    return (
        <Page title="Awards">
			<PageNav>
				<PageNavBreadcrumbs>
					<PageNavCrumb title="Awards"/>
					<PageNavCrumbLink title="Find awards/requests" href="/awards/admin"/>
				</PageNavBreadcrumbs>
			</PageNav>
            <UserSearch placeholder="Type here to search" group="staff student" limit={100}
                        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 gap-1">
                {
                    (user) => (
                        <Link to={"/awards/admin/" + user.emailAddress}
                              className="group bg-white p-3 rounded shadow hover:shadow-lg z-0 hover:z-10 grid grid-cols-2">
                            <div className="text-left text-xs group-hover:font-bold truncate">{user.firstname} {user.lastname}</div>
                            <div className="text-right text-xs text-slate-500 truncate">{user.emailAddress}</div>
                        </Link>
                    )
                }
            </UserSearch>
        </Page>
    );
}
