import {createContext, Fragment, useContext, useState} from "react";
import {twMerge} from "tailwind-merge";
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/24/solid";
import {Button, If} from "components/ui";

const SATURDAY = 6;
const SUNDAY = 0;

const DAYS_IN_WEEK = 5;
const MILLIS_IN_DAY = 1000*60*60*24;

function workingDaysBetween(startDate, endDate) {
	let count = 0;
	let d = new Date(startDate);
	 while (endDate - d > MILLIS_IN_DAY) {
		let day = d.getDay();
		if (day !== SATURDAY && day !== SUNDAY) {
			count++;
		}
		d.setDate(d.getDate() + 1);
	}
	return count;
}

function findBellTime(startDate, bellTimes) {
	let now = new Date();

	let day = now.getDay();
	if (day === SATURDAY || day === SUNDAY) {
		return {};
	}

	let maxDayNumber = bellTimes.map(bt => bt["DayNumber"])
		.reduce((d1, d2) => Math.max(d1, d2), 1);
	let dayNumber = workingDaysBetween(startDate, now) % maxDayNumber + 1;

	let localeTime = now.toLocaleTimeString("en-AU", {"hour12": false});
	return bellTimes.find(bellTime => parseInt(bellTime["DayNumber"]) === dayNumber &&
		bellTime["StartTime"] <= localeTime && localeTime < bellTime["EndTime"]) ?? {"DayNumber": dayNumber};
}

const timetableContext = createContext();

export function Timetable({startDate, bellTimes, forEach, children}) {
	let bellTime = findBellTime(startDate, bellTimes);

	let currentDayNumber = bellTime["DayNumber"];
	let currentPeriod = bellTime["Period"];

	let [dayNumber, setDayNumber] = useState(currentDayNumber ? parseInt(currentDayNumber) : 1);
	let week = Math.ceil(dayNumber/DAYS_IN_WEEK);

	bellTimes = Object.groupBy(bellTimes, bellTime => bellTime["DayName"]);
	let maxDayNumber = Object.keys(bellTimes).length;

	let tryDayNumber = dn => setDayNumber(Math.min(maxDayNumber, Math.max(1, dn)));

	let items = Object.groupBy(forEach, bellTime => bellTime["DayName"]);
	for (let dayName in items) {
		items[dayName] = Object.groupBy(items[dayName], item => item["Period"]);
	}

	return (
		<div className="bg-white rounded shadow grid grid-flow-row gap-1">
			<If condition={maxDayNumber > DAYS_IN_WEEK}>
				<div className="p-1 grid grid-cols-2 gap-1 max-xl:hidden">
					<Button type="button"
							onClick={() => tryDayNumber(dayNumber - DAYS_IN_WEEK)}>
						<ChevronLeftIcon className="inline size-6 text-white"/>
					</Button>
					<Button type="button"
							onClick={() => tryDayNumber(dayNumber + DAYS_IN_WEEK)}>
						<ChevronRightIcon className="inline size-6 text-white"/>
					</Button>
				</div>
			</If>
			<If condition={maxDayNumber> 1}>
				<div className="p-1 grid grid-cols-2 gap-1 xl:hidden">
					<Button type="button"
							onClick={() => tryDayNumber(dayNumber-1)}>
						<ChevronLeftIcon className="inline size-6 text-white"/>
					</Button>
					<Button type="button"
							onClick={() => tryDayNumber(dayNumber+1)}>
						<ChevronRightIcon className="inline size-6 text-white"/>
					</Button>
				</div>
			</If>
			<timetableContext.Provider value={[week, dayNumber.toString(), currentDayNumber, currentPeriod]}>
				<BellTimeTimetable bellTimes={bellTimes}>
					{
						(dayName, period) => {
							items[dayName] ??= {};
							items[dayName][period] ??= [];
							return items[dayName][period].map((item, i) =>
								<Fragment key={i}>{children(item)}</Fragment>)
						}
					}
				</BellTimeTimetable>
			</timetableContext.Provider>
		</div>
	);
}

function BellTimeTimetable({bellTimes, children}) {
	let dayNames = Object.keys(bellTimes);

	let maxPeriods = Object.values(bellTimes).reduce((max, periods) =>
		periods.length > max ? periods.length : max, 0);
	let periods = Array.from(Array(maxPeriods).keys());

	return (
		<div className="grid grid-flow-row">
			{
				periods.map(j => (
					<div key={j} className="grid grid-flow-col auto-cols-fr">
						{
							dayNames.map((dayName, i) => {
								let bellTime = bellTimes[dayName][j];
								if (!bellTime) {
									return <div key={i}/>
								}
								let period = bellTime["Period"];
								return (
									<BellTimeTimetableItem key={i} bellTime={bellTime}>
										{children(dayName, period)}
									</BellTimeTimetableItem>
								);
							})
						}
					</div>
				))
			}
		</div>
	);
}

function BellTimeTimetableItem({bellTime, children}) {
	let [activeWeek, activeDayNumber, currentDayNumber, currentPeriod] = useContext(timetableContext);

	let dayNumber = bellTime["DayNumber"];
	let week = Math.ceil(dayNumber/DAYS_IN_WEEK);

	let dayName = bellTime["DayName"];
	let period = bellTime["Period"];

	let startTime = bellTime["StartTime"];

	return (
		<If condition={week === activeWeek}>
			<div className={twMerge("p-1 flex flex-row gap-1 flex-nowrap",
					(dayNumber != activeDayNumber) && "max-xl:hidden",
					(dayNumber == currentDayNumber) && "xl:bg-yellow-50",
					(dayNumber == currentDayNumber) && (period === currentPeriod) && "!bg-yellow-100",
					(children.length > 1) && "!bg-red-100")}>
				<div className="shrink-0 w-10 min-h-full flex flex-col place-content-center">
					<div className="text-xs text-center text-slate-400">{dayName}</div>
					<div className="text-xs text-center text-slate-600 font-bold truncate">{period}</div>
					<div className="text-xs text-center text-slate-400">{startTime}</div>
				</div>
				<div className="grow grid grid-flow-row my-auto gap-1">{children}</div>
			</div>
		</If>
	);
}
