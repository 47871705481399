import {createContext, useContext, useEffect, useState} from "react";
import {useParams, useNavigate} from "react-router-dom";
import {Tab} from "@headlessui/react";
import {useApi} from "components/token";
import {
    AwardRequestLog,
    AwardRequestSearch,
    AwardRequestSearchResult,
    AwardSearch,
} from "components/awards";
import {Button, If, Loading} from "components/ui";
import {
	Page, PageModal,
	PageNav, PageNavBreadcrumbs, PageNavCrumb, PageNavCrumbLink,
	PageTab, PageTabList
} from "components/layout";

let awardContext = createContext();

export default function AwardsAdminUserProfile() {
    let {emailAddress} = useParams();
    let navigate = useNavigate();

    let [user, fetchUser] = useApi();
    let {firstname, lastname, groups} = user ?? {};
    groups ??= [];

    let [awards, fetchAwards] = useApi();
    let [decidedRequests, fetchDecidedRequests] = useApi();
    let [undecidedRequests, fetchUndecidedRequests] = useApi();

    let [award, setAward] = useState();
    let [deleteFormOpen, setDeleteFormOpen] = useState(false);

    let [submit, setSubmit] = useState(false);
    let [, submitForm] = useApi();

    useEffect(() => {
        fetchUser("GET", `/user/${emailAddress}`)
            .then(() => {
                fetchAwards("GET", `/awards/${emailAddress}`);
                fetchDecidedRequests("GET", `/awards/requests/decided/${emailAddress}`);
                fetchUndecidedRequests("GET", `/awards/requests/undecided/${emailAddress}`);
            })
            .catch(() => {
                let url = {
                    pathname: "/awards/admin"
                };
                navigate(url);
            });
    }, [emailAddress]);

    function handleDeleteFormSubmit(awardId) {
        setSubmit(true);
        submitForm("DELETE", `/awards/award/${awardId}`)
            .then(() => fetchAwards())
            .then(() => setDeleteFormOpen(false))
            .then(() => setSubmit(false));
    }

    return (
        <Page title="Awards">
			<PageNav>
				<PageNavBreadcrumbs>
					<PageNavCrumb title="Awards"/>
					<PageNavCrumbLink title="Find awards/requests" href="/awards/admin"/>
					<If condition={firstname && lastname}>
						<PageNavCrumbLink title={`${firstname} ${lastname}`} href={`/awards/admin/${emailAddress}`}/>
					</If>
				</PageNavBreadcrumbs>
			</PageNav>
            <PageModal open={deleteFormOpen} setOpen={setDeleteFormOpen}>
                <Loading condition={!submit} className="w-1/5 max-w-16 mx-auto">
                    <AwardsUserProfileDeleteAwardForm award={award} onSubmit={handleDeleteFormSubmit}/>
                </Loading>
            </PageModal>
            <Loading condition={groups.length > 0} className="w-1/5 max-w-16 mx-auto">
                <Tab.Group defaultIndex={1}>
                    <If condition={groups.includes("student")}>
                        <PageTabList>
                            <PageTab>AWARDS</PageTab>
                            <PageTab>AWARD REQUESTS</PageTab>
                        </PageTabList>
                    </If>
                    <Tab.Panels>
                        <Tab.Panel>
                            <awardContext.Provider value={[[award, setAward], [deleteFormOpen, setDeleteFormOpen]]}>
                                <AwardsAdminUserProfileAwardsTabPanel awards={awards ?? []}/>
                            </awardContext.Provider>
                        </Tab.Panel>
                        <Tab.Panel>
                            <AwardsAdminUserProfileAwardRequestsTabPanel undecidedAwardRequests={undecidedRequests}
                                                                         decidedAwardRequests={decidedRequests}/>
                        </Tab.Panel>
                    </Tab.Panels>
                </Tab.Group>
            </Loading>
        </Page>
    );
}

function AwardsUserProfileDeleteAwardForm({award, onSubmit}) {
    let template = award.template;

    function handleSubmit(e) {
        e.preventDefault();
        onSubmit(award.awardId);
    }

    return (
        <form onSubmit={handleSubmit} className="space-y-3">
            <div className="grid grid-flow-col-dense">
                <abbr title={template.displayName}
                      className="no-underline text-xs group-hover:font-bold text-slate-600 max-sm:truncate">{template.displayName}</abbr>
                <span className="text-right text-xs text-slate-500 truncate">{award.createdAt}</span>
            </div>
            <abbr title={template.description}
                  className="no-underline max-sm:hidden text-xs text-slate-400">{template.description}</abbr>
            <p className="text-xs text-slate-500"><span className="font-bold">Warning</span>, are you sure you want to delete the award? The award will be deleted but award request associated with the award will not be deleted.</p>
            <p className="text-xs text-slate-500 font-bold">This action cannot be reverted.</p>
            <Button type="submit">DELETE</Button>
        </form>
    );
}

function AwardsAdminUserProfileAwardsTabPanel({awards}) {
    return (
        <AwardSearch awards={awards}
                     placeholder="Type here to search" className="grid grid-cols-1 2xl:grid-cols-2 gap-1">
            {(award) => <AwardsAdminUserProfileAwardsSearchAward award={award}/>}
        </AwardSearch>
    );
}

function AwardsAdminUserProfileAwardsSearchAward({award}) {
    let [[, setAward], [,setDeleteFormOpen]] = useContext(awardContext);
    let template = award.template;

    function handleClick(e) {
        e.preventDefault();
        setAward(award);
        setDeleteFormOpen(true);
    }

    return (
        <div className="group bg-white p-3 rounded shadow hover:shadow-lg z-0 hover:z-10 grid grid-flow-row-dense gap-3">
            <div className="grid grid-flow-row-dense gap-1">
                <div className="grid grid-flow-col-dense">
                    <abbr title={template.displayName}
                          className="no-underline text-xs group-hover:font-bold text-slate-600 max-sm:truncate">{template.displayName}</abbr>
                    <span className="text-right text-xs text-slate-500 truncate">{award.createdAt}</span>
                </div>
                <abbr title={template.description}
                      className="no-underline text-xs text-slate-400">{template.description}</abbr>
            </div>
            <Button type="button" onClick={handleClick}
                    className="w-fit">DELETE</Button>
        </div>
    );
}

const requestContext = createContext();

function AwardsAdminUserProfileAwardRequestsTabPanel({undecidedAwardRequests, decidedAwardRequests}) {
    let [request, setRequest] = useState();
    let [open, setOpen] = useState(false);

    let requests = [];
    requests.push.apply(requests, undecidedAwardRequests);
    requests.push.apply(requests, decidedAwardRequests);

    return (
        <requestContext.Provider value={[[request, setRequest], [open, setOpen]]}>
            <PageModal open={open} setOpen={setOpen}>
                <AwardsUserProfileAwardRequest awardRequest={request}/>
            </PageModal>
            <AwardRequestSearch placeholder="Type here to search" className="grid grid-cols-1 2xl:grid-cols-2 gap-1"
                                awardRequests={requests} limit={100}>
                {(request) => <AwardsUserProfileAwardRequestsSearchAwardRequest awardRequest={request}/>}
            </AwardRequestSearch>
        </requestContext.Provider>
    );
}

function getUserDisplayName(user) {
    if (typeof user === "string") {
        return user;
    }
    let {firstname, lastname} = user;
    return `${firstname} ${lastname}`;
}

function AwardsUserProfileAwardRequest({awardRequest}) {
    let template = awardRequest.template;
    let awardedBy = awardRequest.user ? `${awardRequest.user.firstname} ${awardRequest.user.lastname}` : awardRequest.emailAddress;
    let recipients = awardRequest.recipients.map(r => getUserDisplayName(r));

    return (
        <div className="grid grid-flow-row-dense gap-3">
            <span className="text-base text-slate-600 font-medium">{template.displayName}</span>
            <span className="text-xs text-slate-500">{awardRequest.createdAt}</span>
            <div className="space-x-1 text-xs text-slate-600">
                <If condition={awardRequest.state !== "UNDECIDED"}>
                    <span className={`inline-block p-1 rounded ${awardRequest.state === "ACCEPTED" ? "bg-green-600" : "bg-red-600"} text-white font-bold capitalize`}>{awardRequest.state}</span>
                    <span>by</span>
                </If>
                <If condition={awardRequest.state === "UNDECIDED"}>
                    <span>Requested to</span>
                </If>
                <span className="font-medium">{awardedBy}</span>
            </div>
            <div className="space-x-1 text-xs text-slate-600">
                <span>{recipients.length > 1 ? "Recipients" : "Recipient"}</span>
                <span className="font-medium">{recipients.join(", ")}</span>
            </div>
            <AwardRequestLog awardRequest={awardRequest}/>
        </div>
    );
}


function AwardsUserProfileAwardRequestsSearchAwardRequest({awardRequest}) {
    let [[, setRequest], [,setOpen]] = useContext(requestContext);

    function handleClick() {
        setRequest(awardRequest);
        setOpen(true);
    }

    return (
        <AwardRequestSearchResult awardRequest={awardRequest} onClick={handleClick}/>
    );
}
